<template>
  <div>
    <div class="container text-center lg:grid-cols-2 pb-8">
      <div class="secInd">{{ block.attrs.data.secInd }}</div>
      <h2 class="bigHeadline text-white">
        {{ block.attrs.data.title }}
      </h2>
      <p
        class="subHeadline"
        v-html="formatContent(block.attrs.data.subtitle)"
      ></p>
      <p
        v-if="block.attrs.data.content"
        class="mw-lg mx-auto sectioncontent"
        v-html="formatContent(block.attrs.data.content)"
      ></p>
      <div v-if="block.attrs.data.contentaccordion" class="fCenter">
        <btn-comp
          :name="buttonLabel"
          v-show="!isAccordionOpen"
          variant="outline"
          btntype="button"
          btnclass="mx-auto my-8"
          @click="toggleAccordion"
        />
      </div>
      <transition name="fade">
        <p
          v-if="block.attrs.data.contentaccordion"
          v-show="isAccordionOpen"
          class="mw-lg mx-auto sectioncontent accordioncontent mt-4"
          v-html="formatContent(block.attrs.data.contentaccordion)"
        ></p>
      </transition>
    </div>
  </div>
</template>

<script>
import btnComp from '@/components/framework/btnComp.vue'

export default {
  components: { btnComp },
  props: {
    block: Object,
  },
  data() {
    return {
      isAccordionOpen: false, // Track if the accordion is open or closed
      buttonLabel: 'MEHR LESEN', // Track the button label
    }
  },
  methods: {
    formatContent(content) {
      // Replace \n with <br> for HTML line breaks
      if (content) {
        return content.replace(/\n/g, '<br>')
      }
      return content
    },
    toggleAccordion() {
      this.isAccordionOpen = !this.isAccordionOpen
      this.buttonLabel = this.isAccordionOpen ? 'SCHLIEßEN' : 'MEHR LESEN'
    },
  },
}
</script>
