<template>
  <Teleport to="body">
    <div
      class="fixed fitzekModal w-full h-full top-0 left-0 flex items-center justify-center z-10"
      v-if="open"
    >
      <div
        class="absolute w-full h-full bg-gray-900 opacity-80"
        @click="close"
      ></div>
      <div
        class="absolute fitzekModalContent pb-20 max-h-full"
        :class="maxWidth"
      >
        <div class="container">
          <div class="fxEnd">
            <div
              @click="close"
              class="text-6xl hover:text-gray-600 cursor-pointer"
            >
              &#215;
            </div>
          </div>

          <div class="max-h-full px-4 py-4">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from 'vue'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      default: 'full',
      validator: (value) => ['xs', 'sm', 'md', 'lg', 'full'].includes(value),
    },
  },
  setup(props, { emit }) {
    const open = ref(true)

    const close = () => {
      open.value = false
      emit('close')
    }

    const maxWidth = computed(() => {
      switch (props.width) {
        case 'xs':
          return 'max-w-lg'
        case 'sm':
          return 'max-w-xl'
        case 'md':
          return 'max-w-2xl'
        case 'lg':
          return 'max-w-3xl'
        case 'full':
          return 'max-w-full'
        default:
          return 'max-w-full'
      }
    })

    const onEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        close()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', onEscape)
      // Add modalOpen class if open is true initially
      if (open.value) {
        document.body.classList.add('modalOpen')
      }
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', onEscape)
      // Clean up class on unmount to prevent side effects
      document.body.classList.remove('modalOpen')
    })

    // Watch the open state and update body class
    watch(open, (newValue) => {
      if (newValue) {
        document.body.classList.add('modalOpen')
      } else {
        document.body.classList.remove('modalOpen')
      }
    })

    return {
      open,
      close,
      maxWidth,
    }
  },
}
</script>

<style>
.modalOpen {
  overflow: hidden;
}
</style>
