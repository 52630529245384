<template>
  <div>
    <div class="container">
      <div class="grid grid-cols-1 lg:grid-cols-3 relative">
        <div
          v-for="step in stepsOutput"
          :key="step.steps_title"
          class="step__item flex flex-col items-center text-center px-10 relative max-w-screen-md mx-auto mb-5 lg:mb-0"
        >
          <img class="step__image mb-5" :src="step.steps_image" alt="" />
          <h3 class="step__title subHeadline font-body">
            {{ step.steps_title }}
          </h3>
          <p>{{ step.steps_description }}</p>
        </div>
        <!-- Chevron 1 -->
        <div
          class="step__chevron absolute left-1/3 transform -translate-x-1/2 hidden lg:block"
        >
          <img
            src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/08/28110225/fitzek-chevrons-right.svg"
            alt=""
            class="w-16 h-auto"
          />
        </div>
        <!-- Chevron 2 -->
        <div
          class="step__chevron absolute left-2/3 transform -translate-x-1/2 hidden lg:block"
        >
          <img
            src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/08/28110225/fitzek-chevrons-right.svg"
            alt=""
            class="w-16 h-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'StepsComponent',
  props: {
    block: {
      type: Object,
    },
  },
  setup(props) {
    const stepsOutput = computed(() => {
      const steps = props.block.attrs.data.steps

      if (Array.isArray(steps)) {
        return steps.map((step) => ({
          steps_image: step.image.url,
          steps_title: step.title,
          steps_description: step.description,
        }))
      }

      return []
    })

    return { stepsOutput }
  },
}
</script>

<style scoped>
.step__image {
  height: 100%;
  max-height: 250px;
  object-fit: cover;
  /* transition: 0.3s all ease; */
}

.step__title {
  margin-bottom: 0.5rem;
}

.step__chevron {
  margin-top: 8rem;
}

.step__chevron img {
  width: 100%;
  max-width: 80px;
  transition: 0.3s all ease;
}

@media (max-width: 1200px) {
  .step__image {
    max-height: 200px;
  }

  .step__chevron {
    margin-top: 7rem;
  }

  .step__chevron img {
    width: 100%;
    max-width: 60px;
  }
}
</style>
