import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LegalView from '@/views/LegalView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: (route) => ({
      acfOptions: route.params.acfOptions,
      landingPage: route.params.landingPage,
      isPeripheral: '',
      previewId: null,
    }),
  },
  {
    path: '/datenschutz',
    name: 'privacy',
    component: LegalView,
    props: (route) => ({
      acfOptions: route.params.acfOptions,
      landingPage: route.params.landingPage,
      isPeripheral: 'datenschutz',
    }),
  },
  {
    path: '/impressum',
    name: 'imprint',
    component: LegalView,
    props: (route) => ({
      acfOptions: route.params.acfOptions,
      landingPage: route.params.landingPage,
      isPeripheral: 'impressum',
    }),
  },
  {
    path: '/teilnahmebedingungen',
    name: 'teilnahme',
    component: LegalView,
    props: (route) => ({
      acfOptions: route.params.acfOptions,
      landingPage: route.params.landingPage,
      isPeripheral: 'teilnahmebedingungen',
    }),
  },
  {
    path: '/preview/:id',
    name: 'preview',
    component: HomeView,
    props: (route) => ({
      acfOptions: route.params.acfOptions,
      landingPage: route.params.landingPage, // This will be fetched based on the id
      isPeripheral: 'preview',
      previewId: route.params.id, // Pass the preview ID to the component
    }),
  },

  // other routes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
