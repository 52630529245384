<template>
  <div>
    <div v-if="landingPage">
      <div v-if="landingPage.acf_blocks && landingPage.acf_blocks.length">
        <div
          v-for="block in landingPage.acf_blocks"
          :key="block.attrs.id"
          ref="sections"
          class="section"
          :data-section-id="block.blockName"
        >
          <VideoModal
            class="video_modal"
            v-if="block.blockName === 'acf/videomodal'"
            :block="block"
          />
          <HeroComponent
            class="section"
            v-if="block.blockName === 'acf/hero'"
            :block="block"
          />
          <VideoSection
            class="section"
            v-if="block.blockName === 'acf/videos'"
            :block="block"
          />
          <BookIntro
            class="section"
            id="buch"
            v-if="block.blockName === 'acf/bookintro'"
            :block="block"
          />
          <StepsComponent
            class="section"
            id="steps"
            v-if="block.blockName === 'acf/steps'"
            :block="block"
          />
          <ContestantReveal
            class="section"
            id="contestants"
            v-if="block.blockName === 'acf/contestantreveal'"
            :block="block"
          />
          <gewinnspielCom
            class="section"
            id="challenge"
            v-if="block.blockName === 'acf/gewinnspiel'"
            :block="block"
          />
          <PriceDisplay
            class="section"
            v-if="block.blockName === 'acf/pricedisplay'"
            :block="block"
          />
          <BookBuy
            class="section"
            id="bestellen"
            v-if="block.blockName === 'acf/bookbuy'"
            :block="block"
          />
          <TourComponent
            class="section"
            id="livetour"
            v-if="block.blockName === 'acf/tourdates'"
            :block="block"
          />
        </div>
      </div>
      <ShopComp :acfOptions="acfOptions" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import BookIntro from '@/components/sections/BookIntro.vue'
import PriceDisplay from '@/components/sections/PriceDisplay.vue'
import BookBuy from '@/components/sections/BuyBookComponent.vue'
import TourComponent from '@/components/sections/TourComponent.vue'
import HeroComponent from '@/components/sections/HeroComponent.vue'
import gewinnspielCom from '@/components/sections/gewinnspielCom.vue'
import VideoSection from '@/components/sections/videoSection.vue'
import StepsComponent from '@/components/sections/StepsComponent.vue'
import ContestantReveal from '@/components/sections/ContestantReveal.vue'
import VideoModal from '@/components/sections/videoModal.vue'
import ShopComp from '@/components/framework/ShopComp.vue'

gsap.registerPlugin(ScrollTrigger)

export default {
  components: {
    BookIntro,
    PriceDisplay,
    BookBuy,
    TourComponent,
    HeroComponent,
    gewinnspielCom,
    VideoSection,
    StepsComponent,
    ContestantReveal,
    VideoModal,
    ShopComp,
  },
  props: {
    acfOptions: Object,
    landingPage: Object,
    isPeripheral: String,
  },
  mounted() {
    this.initAnimations()
  },
  methods: {
    initAnimations() {
      this.$nextTick(() => {
        const sections = this.$refs.sections
        if (sections && sections.length) {
          sections.forEach((section) => {
            const headlines = section.querySelectorAll(
              '.secInd, .bigHeadline, .subHeadline, .btnCompWrapper, .sectioncontent, .step__image'
            )
            if (headlines.length) {
              gsap.from(headlines, {
                y: 50,
                opacity: 0,
                duration: 1,
                stagger: 0.2,
                scrollTrigger: {
                  trigger: section,
                  start: 'top 80%',
                  toggleActions: 'play none none reverse',
                },
              })
            }
          })
        } else {
          // console.warn('No sections found or sections are empty')
        }
      })
    },
  },
}
</script>

<style>
.section {
  margin-bottom: 2rem;
}
</style>
