<template>
  <div>
    <div class="w-full">
      <div
        class="headerBg bg-no-repeat tourHeader bg-center pt-24 pb-8"
        :style="{
          backgroundImage: `url(${block.attrs.data.tourbg.url})`,
        }"
      >
        <div class="container">
          <div class="tourdateHeader text-center">
            <div class="secInd">
              {{ block.attrs.data.secInd }}
            </div>
            <h2
              v-html="formatContent(block.attrs.data.title)"
              class="bigHeadline relative z-10 text-white"
            ></h2>
          </div>
          <div
            class="tourdates-grid mt-20 mw-xl mx-auto lg:grid gap-0 lg:grid-cols-3 md:gap-6"
          >
            <div
              v-for="(column, index) in tourDatesColumns"
              :key="index"
              :class="['tourdate-column', columnClass(index)]"
            >
              <div
                v-for="date in column"
                :key="date.datum"
                class="tourdate-item w-full text-xl uppercase fyCenter mb-0 md:mb-4 lg:mb-0 pe-0 lg:pe-5"
              >
                <p
                  class="tourDateDate"
                  v-html="formatContent(stripYear(date.datum))"
                ></p>

                <h3
                  class="text-lg md:text-2xl text-white"
                  v-html="formatContent(decodeUnicode(date.title))"
                ></h3>

                <div class="zusatzCont" v-if="date.zusatz">
                  <img
                    v-if="date.zusatz === '[RESTTICKETS]'"
                    src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/08/16130246/icon-resttickets.png"
                    alt="Resttickets"
                    class="me-4 sm:me-6 resttickets"
                  />
                  <img
                    v-else-if="date.zusatz === '[AUSVERKAUFT]'"
                    src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/08/16130330/icon-ausverkauft.png"
                    alt="Ausverkauft"
                  />
                  <span v-else>{{ date.zusatz }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Button -->
          <div class="fCenter mt-12">
            <btn-comp
              variant="outline"
              btntype="a"
              btnclass="ml-4"
              :href="block.attrs.data.tickets"
              name="TICKETS & INFOS"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import btnComp from '@/components/framework/btnComp.vue'

export default {
  props: {
    block: Object,
  },
  components: {
    btnComp,
  },
  data() {
    return {
      tourDatesColumns: [[], [], []],
    }
  },
  created() {
    this.prepareTourDates()
  },
  methods: {
    formatContent(content) {
      if (content) {
        return content.replace(/\n/g, '<br>')
      }
      return content
    },
    decodeUnicode(str) {
      return decodeURIComponent(JSON.parse(`"${str.replace(/\\u/g, '%u')}"`))
    },
    stripYear(dateStr) {
      return dateStr.split('.').slice(0, 2).join('.') + '.'
    },
    prepareTourDates() {
      let tourDates
      try {
        // Attempt to parse tourDates if it is a JSON string
        if (typeof this.$root.acfOptions.tourdates === 'string') {
          tourDates = JSON.parse(this.$root.acfOptions.tourdates)
        } else {
          tourDates = this.$root.acfOptions.tourdates
        }

        // Ensure tourDates is an array
        if (Array.isArray(tourDates)) {
          // Sort by date
          tourDates.sort(
            (a, b) =>
              new Date(a.datum.split('.').reverse().join('-')) -
              new Date(b.datum.split('.').reverse().join('-'))
          )

          // Determine the number of items per column
          const itemsPerColumn = Math.ceil(tourDates.length / 3)

          // Split into three columns
          this.tourDatesColumns[0] = tourDates.slice(0, itemsPerColumn)
          this.tourDatesColumns[1] = tourDates.slice(
            itemsPerColumn,
            itemsPerColumn * 2
          )
          this.tourDatesColumns[2] = tourDates.slice(itemsPerColumn * 2)
        } else {
          console.error('tourDates is not an array:', tourDates)
        }
      } catch (error) {
        console.error('Error processing tourDates:', error)
      }
    },
    columnClass(index) {
      return index === 0
        ? 'left-align'
        : index === 1
        ? 'center-align'
        : 'right-align'
    },
  },
}
</script>

<style scoped>
.tourdates-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.tourdate-column {
  display: flex;
  flex-direction: column;
}
.tourHeader {
  min-height: 80vh;
  background-size: auto 100%;
  background-position-y: top;
  background-size: contain;
}

.tourdate-item h3 {
  font-family: 'futura-pt', 'sans-serif' !important;
  font-weight: 600;
}

.tourdateHeader {
  padding-top: 18.5%;
}

@media (min-width: 2000px) {
  .tourdateHeader {
    padding-top: 35%;
  }
}

.tourDateDate {
  color: var(--cGrey);
  font-weight: 600;
  width: 3.7rem;
}

@media (min-width: 768px) {
  .tourdate-column.center-align {
    justify-content: center;
  }
  .tourdate-column.right-align {
    justify-content: flex-end;
  }
}
@media (min-width: 1180px) {
  .tourdates-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1024px) {
  .tourdate-item {
    border-bottom: 1px solid #fff;
    min-height: 50px;
  }

  .tourdates-grid {
    gap: 0;
  }
}

.zusatzCont {
  align-self: center;
  @apply mr-0 ml-auto;
}
.zusatzCont img {
  max-width: 170px;
  height: auto;
}

.zusatzCont .resttickets {
  max-width: 120px;
}

@media (max-width: 576px) {
  .tourdate-item {
    border: none;
    min-height: 40px;
  }
  .zusatzCont img {
    max-width: 150px;
  }
  .tourHeader {
    background-size: 153% auto;
  }
}
</style>
