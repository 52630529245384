import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://kla.brotsalz.de/wp-json/',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  auth: {
    username: 'apikla',
    password: '2V&CveHv&97CnrL!a*NKH&0P',
  },
})

export default instance
