<template>
  <footer class="fiFooter relative w-full">
    <div
      v-if="footerBgImg"
      class="footerBgImgCont"
      :style="{
        backgroundImage: `url(${footerBgImg})`,
      }"
    ></div>
    <div ref="section" class="section min-h-auto">
      <div
        id="newsletter"
        class="container pb-12 pt-24 md:py-24 text-center fCenter"
      >
        <div class="mw-md">
          <div class="secInd">SEBASTIAN FITZEK</div>
          <h2 class="bigHeadline text-white">NEWSLETTER</h2>
          <div class="subHeadline formSubheadline">
            Bleibe auf dem Laufenden
          </div>
          <p class="mb-6 sectioncontent lg:mb-10">
            Abonniere den kostenlosen Newsletter von Droemer Knaur und verpasse
            <br class="none md:block" />
            keine Buchempfehlungen mehr.
          </p>
          <div class="nlAnmeldung mw-sm sectioncontent mx-auto">
            <form
              action="https://holtzbrinck.scnem.com/art_resource.php?sid=4s7p.1rp044g"
              method="post"
              ref="profileForm"
              accept-charset="utf-8"
              id="profileform"
              target="_blank"
              c
            >
              <div id="form" class="">
                <div id="formfields">
                  <div class="mb-2" id="formfieldwrapper">
                    <label
                      :class="{
                        'attributeemai hidden typeemail d-none mandatory': true,
                        'text-red-600': emailError,
                      }"
                      for="form_EMAIL"
                    >
                      eMail
                    </label>
                    <input
                      type="text"
                      name="form_EMAIL"
                      id="form_EMAIL"
                      required
                      class="attributeemail typeemail mandatory"
                      placeholder="Deine E-Mail-Adresse"
                      v-model="email"
                    />
                    <div v-if="emailError" class="text-red-600 mt-4 text-sm">
                      Bitte gib eine gültige E-Mail-Adresse ein.
                    </div>
                  </div>
                </div>
                <div class="dsgvoWrapper py-6 flex">
                  <div class="checkBoxWrap">
                    <div class="checker">
                      <div class="checked">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 hidden"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                    </div>

                    <input
                      type="checkbox"
                      name="dsgvoCheck"
                      id="dsgvoCheck"
                      v-model="isChecked"
                      required
                    />
                  </div>
                  <p
                    ref="checkboxLabel"
                    class="legalNl text-left ml-4 text-bold mw-sm mx-auto"
                    :style="{
                      fontSize: '16px',
                      color: checkboxLabelColor,
                    }"
                  >
                    Mit der Registrierung für den Newsletter willigst du ein,
                    dass du aktuelle Informationen und Angebot der Verlagsgruppe
                    Droemer Knaur und der Holtzbrinck Verlagsgruppe erhältst.
                    Weitere Informationen zum Datenschutz findest du
                    <a class="cRed" href="/datenschutz">hier.</a>
                    <span
                      v-if="checkboxError"
                      class="text-red-600 block text-sm mt-4"
                    >
                      Bitte akzeptiere die Datenschutzbestimmungen.
                    </span>
                  </p>
                </div>
                <div class="btnCompWrapper fCenter">
                  <div class="btnComp btnCTA btnComp--prime">
                    <input
                      type="submit"
                      class=""
                      value="JETZT ZUM NEWSLETTER ANMELDEN"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.758"
                      height="16.687"
                      viewBox="0 0 9.758 16.687"
                    >
                      <path
                        id="Path_440"
                        data-name="Path 440"
                        d="M629.532,520.334l7.636,7.636-7.636,7.636"
                        transform="translate(-628.824 -519.627)"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <!-- 
                <div id="actions">
                  <btn-comp
                    variant="prime"
                    btntype="button"
                    btnclass="mx-auto mt-6"
                    name="ZUM NEWSLETTER ANMELDEN"
                    @click="validateForm"
                  ></btn-comp>
                </div> -->

                <!-- Success and Error Messages -->
                <div v-if="formSuccess" class="text-green-600 mt-4">
                  Vielen Dank für deine Anmeldung!
                </div>
                <div v-if="formError" class="text-red-600 mt-4">
                  Es gab ein Problem bei der Anmeldung. Bitte versuche es
                  erneut.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="overFooterLogo fCenter">
        <CompLogos class="fCenter" comp="fitzek" />
      </div>
      <div class="footerCont"></div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-16">
        <div class="">
          <div class="text-center">
            <div class="socialCompLogoCont">
              <CompLogos comp="fitzek" />
            </div>
            <div class="subHeadline uppercase followHeadline">
              Folge Sebastian Fitzek hier
            </div>
            <div class="fCenter">
              <SocialIcons comp="fitzek" />
            </div>
          </div>
        </div>
        <div class="mt-12 lg:mt-0">
          <div class="text-center">
            <div class="socialCompLogoCont">
              <CompLogos comp="droemer" />
            </div>
            <div class="subHeadline uppercase followHeadline">
              Folge DROEMER KNAUR hier
            </div>
            <div class="fCenter">
              <SocialIcons comp="droemer" />
            </div>
          </div>
        </div>
      </div>
      <div class="legalFooterRow">
        <div>
          <router-link to="/datenschutz">Datenschutz</router-link>
        </div>
        <div>
          <router-link to="/impressum">Impressum</router-link>
        </div>
        <div v-if="conditionsLink.is_tbd">
          <router-link to="/teilnahmebedingungen"
            >Teilnahmebedingungen</router-link
          >
        </div>
        <div>
          <a href="#" @click="showPrivacySettings"
            >Privatsphäre-Einstellungen</a
          >
        </div>
      </div>
      <div class="row uppercase pb-32 text-center" style="font-size: 16px">
        <div class="row uppercase pb-32 text-center" style="font-size: 16px">
          <span class="block sm:inline-block"
            >Copyright © {{ currentYear }}</span
          >
          <span class="hidden sm:inline-block mx-2"> | </span>
          <span
            >Design by
            <a target="_blank" href="https://www.brotsalz.de/"
              >brot & salz</a
            ></span
          >
        </div>
      </div>
    </div>
    <div class="bottomContainer"></div>
  </footer>
</template>

<script>
import axios from 'axios'
import CompLogos from '@/components/framework/CompLogos.vue'
// import BtnComp from './btnComp.vue'

export default {
  name: 'Footer',
  components: {
    CompLogos,
    // BtnComp,
  },
  data() {
    return {
      email: '',
      isChecked: false,
      emailError: false,
      checkboxError: false,
      formSuccess: false,
      formError: false,
    }
  },
  computed: {
    footerBgImg() {
      return this.$root.acfOptions.footerbg.url
    },
    currentYear() {
      return new Date().getFullYear()
    },
    checkboxLabelColor() {
      return this.checkboxError ? 'red' : 'white'
    },
    conditionsLink() {
      return this.$root.acfOptions.legal
    },
  },
  methods: {
    validateForm() {
      this.emailError = !this.validateEmail(this.email)
      this.checkboxError = !this.isChecked

      if (!this.emailError && !this.checkboxError) {
        this.submitForm()
      }
    },
    showPrivacySettings() {
      if (window.UC_UI) {
        window.UC_UI.showSecondLayer()
      } else {
        console.error('Usercentrics UI is not available')
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    async submitForm() {
      try {
        const formData = new FormData()
        formData.append('form_EMAIL', this.email)
        formData.append('dsgvoCheck', this.isChecked)

        const response = await axios.post(
          'https://holtzbrinck.scnem.com/art_resource.php?sid=4s7p.1rp044g',
          formData
        )

        console.log('Form submitted', response.data)
        this.formSuccess = true
        this.formError = false
      } catch (error) {
        console.error('Form submission error:', error)
        this.formError = true
        this.formSuccess = false
      }
    },
  },
}
</script>

<style>
.overFooterLogo img {
  max-width: 440px;
  width: 70%;
  height: auto;
}
.overFooterLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply py-8 pb-16;
}
.subHeadline.followHeadline {
  @apply mb-4;
}
.socialCompLogoCont > div {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply h-20 mb-4;
}
.socialCompLogoCont > div img {
  max-width: 180px;
}
.legalFooterRow {
  @apply py-4 block text-center lg:flex justify-center uppercase items-center;
}
.legalFooterRow > div {
  @apply lg:mx-3 py-4;
}
.fiFooter {
  position: relative;
}
.footerBgImgCont {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  @apply py-12;
}
.footerBgImgCont > div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  @apply w-full h-full;
}
.formSubheadline {
  margin-bottom: 1rem !important;
}
@media (max-width: 576px) {
  .overFooterLogo,
  .footerCont {
    display: none !important;
  }
}
</style>
