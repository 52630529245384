import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css' // Add this line
import SocialIcons from '@/components/framework/SocialIcons.vue' // Adjust the import path as needed
import { register } from 'swiper/element/bundle'
import 'swiper/swiper-bundle.css'

register()

createApp(App)
  .use(store)
  .use(router)
  .component('SocialIcons', SocialIcons)
  .mount('#app')
