<template>
  <div v-if="frostAssets && frostAssets.baselayer" id="iceWrapper" class="">
    <div class="frost-anim__wrapper">
      <!-- Base -->
      <div class="frost-anim__base_layer">
        <img
          v-for="baseItem in frostAssets.baselayer"
          :key="baseItem.baseimage"
          :src="baseItem.baseimage"
          alt=""
          class="frost-anim__base_item"
          :class="baseItem.baseclasses"
        />
        <!-- Notice -->
        <div
          class="frost-anim__wipe_notice flex justify-center items-center flex-col text-center"
        >
          <img
            :src="
              isTouchDevice()
                ? frostAssets.noticeicon_mobile.url
                : frostAssets.noticeicon.url
            "
            alt=""
            class="frost-anim__wipe_image"
            :class="isTouchDevice() ? 'click' : 'wipe'"
          />
          <p class="frost-anim__wipe_text mb-0 mt-5 leading-tight">
            {{ this.notices[0] }} <br class="mb-0" />
            {{ this.notices[1] }}
          </p>
        </div>
      </div>
      <!-- Group 1 -->
      <div class="frost-anim__layer frost-anim__layer_1">
        <!-- Background Blur -->
        <img
          :src="frostAssets.frostbackground.url"
          alt=""
          class="frost-anim__background_blur"
        />
        <!-- Animation Items -->
        <img
          v-for="animationItem in frostAssets.animationlayer"
          :key="animationItem.animationimage"
          :src="animationItem.animationimage"
          alt=""
          class="frost-anim__item"
          :class="animationItem.animationclasses"
        />
      </div>
    </div>
    <div id="frost_sound" class="frost_sound__wrapper">
      <audio
        ref="frostPlayer"
        src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/08/15161257/icebreak.aac"
      ></audio>
    </div>
  </div>
  <!-- <div v-else>Loading...</div> -->
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useAudioState } from '@/composables/useAudioState.js'
import { useVideoPlaying } from '@/composables/useVideoPlaying.js'

export default {
  name: 'IceComp',
  props: {
    acfOptions: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const notices = ref(['BEWEGE DEINE MAUS, ', 'UM DICH VOM EIS ZU BEFREIEN'])

    const isTouchDevice = () => {
      return 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0
    }

    const frostAssets = computed(() => {
      return props.acfOptions.frostassets
    })

    const { state } = useAudioState()
    const frostPlayer = ref(null) // Reference for the audio element
    let fadeOutTimeout = null

    onMounted(() => {
      const { videoState } = useVideoPlaying()
      const frostAnimLayer1 = document.querySelector('.frost-anim__layer_1')
      const frostBody = document.querySelector('body')
      const frostAnimWrapper = document.querySelector('.frost-anim__wrapper')
      const main = document.querySelector('#iceWrapper')
      let timeout1
      let timeout2
      let animationTimeout = null

      // Function to handle the activation of animations
      const activateAnimations = () => {
        clearTimeout(timeout1)
        clearTimeout(timeout2)
        clearTimeout(fadeOutTimeout)
        clearTimeout(animationTimeout) // Clear any previous animation timeout

        frostAnimLayer1.classList.remove('active')
        frostAnimWrapper.classList.remove('active')
        main.classList.remove('active')
        frostBody.classList.remove('frost-active')

        // console.log('videoState.videoIsPlaying ' + videoState.videoIsPlaying)
        // Add a delay before activating the animations
        animationTimeout = setTimeout(() => {
          if (!videoState.videoIsPlaying) {
            timeout1 = setTimeout(() => {
              frostAnimLayer1.classList.add('active')
              if (frostPlayer.value && state.isMuted) {
                frostPlayer.value.volume = 1
                frostPlayer.value.currentTime = 0
                frostPlayer.value.play()
              }
            }, 3000)

            timeout2 = setTimeout(() => {
              frostAnimWrapper.classList.add('active')
              main.classList.add('active')
              frostBody.classList.add('frost-active')
            }, 4000)
          }
        }, 500) // 500ms delay to check if the video is still paused
      }

      activateAnimations()

      document.addEventListener('mousemove', activateAnimations)
      document.addEventListener('scroll', activateAnimations)
      document.addEventListener('click', activateAnimations)

      if (isTouchDevice()) {
        notices.value[0] = 'TIPPE DEINEN SCREEN AN, '
      }

      // Watch for changes in the mute state
      watch(
        () => state.isMuted,
        (muteValue) => {
          if (!muteValue) {
            frostPlayer.value.pause()
          }
        }
      )

      // Watch for changes in the mute state
      watch(
        () => videoState.videoIsPlaying,
        (playValue) => {
          if (playValue == false) {
            activateAnimations()
          }
        }
      )
    })

    return { notices, isTouchDevice, frostAssets, frostPlayer }
  },
}
</script>

<style>
body #appfi > div:not([class]),
body #appfi .fiFooter {
  transition: 2s all ease;
  filter: blur(0px);
  transition-delay: 0s;
}

body.frost-active #appfi > div:not([class]),
body.frost-active #appfi .fiFooter {
  filter: blur(3px);

  transition: 10s all ease;
  transition-delay: var(--transSpeedIn);
}
</style>

<style scoped>
/* Frost Wipe Notice ------------------ */
.frost-anim__wipe_notice {
  position: absolute;
  right: 15%;
  bottom: 20%;
  opacity: 0;
  transition: 0.6s all ease;
  transition-delay: 0s;
}

body.frost-active .frost-anim__wipe_notice {
  transition: var(--transSpeedOut) all ease;
  transition-delay: 2s;
  opacity: 1;
}

.frost-anim__wipe_image.wipe {
  transform-origin: bottom;
  transform: rotate(-25deg);
  animation: wipe 3s ease infinite;
}

@keyframes wipe {
  0% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(-25deg);
  }
}

.frost-anim__wipe_image.click {
  transform: scale(1);
  animation: click 3s ease infinite;
}

@keyframes click {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 576px) {
  /* .frost-anim__wipe_notice {
    right: 2rem;
  } */
  .frost-anim__wipe_notice {
    left: 0;
    right: 0;
  }
}

/* Frost Animation
--------------------------------------------- */
#iceWrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 900;
  pointer-events: none !important;
}

.frost-anim__wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 10;
  isolation: isolate;
  pointer-events: none;
  inset: 0;
  position: relative;
}

/* Base ------------------ */
.frost-anim__base_layer {
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.frost-anim__base_item {
  position: absolute;
}

.frost-anim__base_item_1 {
  top: 0;
  left: 0;
  width: 25%;
}

.frost-anim__base_item_2 {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
}

.frost-anim__base_item_3 {
  top: 0;
  right: 0;
  width: 25%;
}

.frost-anim__base_item_4 {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 40%;
}

.frost-anim__base_item_5 {
  bottom: 0;
  right: 0;
  width: 20%;
}

.frost-anim__base_item_6 {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
}

.frost-anim__base_item_7 {
  bottom: 0;
  left: 0;
  width: 20%;
}

/* Background Blur ------------------ */
.frost-anim__background_blur {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: var(--transSpeedOut) all ease;
}

.frost-anim__layer.active .frost-anim__background_blur {
  opacity: 0.35;
  transition: var(--transSpeedIn) all ease;
  transition-delay: 1s;
}

/* Background Tint ------------------ */
.frost-anim__layer_1 {
  background: rgba(72, 194, 255, 0);
  transition: var(--transSpeedOut) all ease;
}

.frost-anim__layer_1.active {
  background: rgba(72, 194, 255, 0.35);
  transition: var(--transSpeedIn) all ease;
}

.frost-anim__layer_1::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  inset: 0;
  background: rgba(252, 254, 255, 0);
  transition: var(--transSpeedOut) all ease;
  transition-delay: 0s;
}

.frost-anim__layer_1.active::before {
  background: rgba(252, 254, 255, 0.35);
  transition: var(--transSpeedIn) all ease;
  transition-delay: var(--transSpeedIn);
}

/* Frost anim ------------------ */
/* Layers */
.frost-anim__layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.frost-anim__layer_1 {
  transform: scale(1);
}

/* Frost Speed Disappear */
.frost-anim__layer:not(.active) .frost-anim__item {
  transition-delay: 0s !important;
  transition-duration: var(--transSpeedOut) !important;
}

/* Frost Speed Appear */
.frost-anim__layer.active .frost-anim__item {
  transition-duration: var(--transSpeedIn) !important;
}

/* Items */
.frost-anim__item {
  position: absolute;
  height: auto;
  object-fit: cover;
  opacity: 0;
  z-index: -1;
}

.frost-anim__layer.active .frost-anim__item {
  opacity: 0.75;
}

/* Frost Top ------------------ */
.bottom.frost-anim__item_1 {
  top: 0%;
  left: -6.5%;
  width: 22%;
  transform: translate(-2rem, -2rem) rotate(90deg) scale(var(--scaleice));
  display: block;
}

.frost-anim__layer.active .bottom.frost-anim__item_1 {
  transition-delay: 0.7s !important;
  transform: translate(0, 0) rotate(90deg) scale(1);
}

.top.frost-anim__item_3 {
  top: 0%;
  left: 10%;
  width: 12%;
  transform: translate(-2rem, -2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_3 {
  transition-delay: 0s !important;
  transform: translate(0, 0) scale(1);
}

.top.frost-anim__item_6 {
  top: 0;
  left: 20%;
  width: 10%;
  transform: translate(-2rem, -2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_6 {
  transition-delay: 2s !important;
  transform: translate(0, 0) scale(1);
}

.top.frost-anim__item_8 {
  top: 0;
  left: 29%;
  width: 10%;
  transform: translate(0, -2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_8 {
  transition-delay: 0.5s !important;
  transform: translate(0, 0) scale(1);
}

.top.frost-anim__item_7.one {
  top: 0;
  right: 38%;
  width: 25%;
  transform: translate(0, -2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_7.one {
  transition-delay: 3s !important;
  transform: translate(0, 0) scale(1);
}

.top.frost-anim__item_9 {
  top: 0;
  right: 25.5%;
  width: 17%;
  transform: translate(2rem, -2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_9 {
  transition-delay: 0.2s !important;
  transform: translate(0, 0) scale(1);
}

.top.frost-anim__item_10 {
  top: 0;
  right: 0%;
  width: 30%;
  transform: translate(2rem, -2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_10 {
  transition-delay: 3.5s !important;
  transform: translate(0, 0) scale(1);
}

/* Frost Right ------------------ */
.right.frost-anim__item_3.one {
  display: block;
  top: 12.5%;
  right: 0;
  width: 12.5%;
  transform: translate(2rem, 0) scale(var(--scaleice));
}

.frost-anim__layer.active .right.frost-anim__item_3.one {
  transition-delay: 0.7s !important;
  transform: translate(0, 0) scale(1);
}

@media (max-width: 992px) {
  .right.frost-anim__item_3.one {
    right: -20%;
    width: 50%;
  }
}

.bottom.frost-anim__item_3 {
  display: block;
  top: 52.5%;
  right: -1.5%;
  width: 10%;
  transform: translate(2rem, 0) rotate(-90deg) scale(var(--scaleice));
}

.frost-anim__layer.active .bottom.frost-anim__item_3 {
  transition-delay: 2s !important;
  transform: translate(0, 0) rotate(-90deg) scale(1);
}

@media (max-width: 992px) {
  .bottom.frost-anim__item_3 {
    right: -20%;
    width: 40%;
  }
}

.left.frost-anim__item_4 {
  display: block;
  bottom: 10%;
  right: -6.5%;
  width: 20%;
  transform: translate(2rem, 0) rotate(180deg) scale(var(--scaleice));
}

.frost-anim__layer.active .left.frost-anim__item_4 {
  transition-delay: 1s !important;
  transform: translate(0, 0) rotate(180deg) scale(1);
}

/* Frost Left ------------------ */
.right.frost-anim__item_3.two {
  display: block;
  bottom: 32%;
  left: 0%;
  width: 10%;
  transform: translate(-2rem, 0) rotate(180deg) scale(var(--scaleice));
}

.frost-anim__layer.active .right.frost-anim__item_3.two {
  transition-delay: 1s !important;
  transform: translate(0, 0) rotate(180deg) scale(1);
}

@media (max-width: 992px) {
  .right.frost-anim__item_3.two {
    left: -30%;
    width: 60%;
  }
}

/* Frost Bottom ------------------ */
.right.frost-anim__item_1 {
  display: block;
  bottom: -6.5%;
  right: -2%;
  width: 20%;
  transform: translate(2rem, 2rem) rotate(45deg) scale(var(--scaleice));
}

.frost-anim__layer.active .right.frost-anim__item_1 {
  transition-delay: 0.4s !important;
  transform: translate(0, 0) rotate(45deg) scale(1);
}

.bottom.frost-anim__item_9 {
  display: block;
  bottom: 0;
  left: -2.5%;
  width: 25%;
  transform: translate(-2rem, 2rem) rotate(90deg) scale(var(--scaleice));
}

.frost-anim__layer.active .bottom.frost-anim__item_9 {
  transition-delay: 3.2s !important;
  transform: translate(0, 0) rotate(90deg) scale(1);
}

.bottom.frost-anim__item_4 {
  display: block;
  bottom: 0;
  left: 20%;
  width: 17.5%;
  transform: translate(0, 2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .bottom.frost-anim__item_4 {
  transition-delay: 0.5s !important;
  transform: translate(0, 0) scale(1);
}

.bottom.frost-anim__item_5 {
  display: block;
  bottom: 0;
  left: 37.5%;
  width: 12.5%;
  transform: translate(0, 2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .bottom.frost-anim__item_5 {
  transition-delay: 1.8s !important;
  transform: translate(0, 0) scale(1);
}

.top.frost-anim__item_7.two {
  display: block;
  bottom: 0;
  right: 30%;
  width: 20%;
  transform: translate(0, 2rem) rotate(180deg) scale(var(--scaleice));
}

.frost-anim__layer.active .top.frost-anim__item_7.two {
  transition-delay: 1s !important;
  transform: translate(0, 0) rotate(180deg) scale(1);
}

.bottom.frost-anim__item_6 {
  display: block;
  bottom: 0;
  right: 22.5%;
  width: 10%;
  transform: translate(2rem, 2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .bottom.frost-anim__item_6 {
  transition-delay: 2.7s !important;
  transform: translate(0, 0) scale(1);
}

.bottom.frost-anim__item_7 {
  display: block;
  bottom: 0;
  right: 12.5%;
  width: 12.5%;
  transform: translate(2rem, 2rem) scale(var(--scaleice));
}

.frost-anim__layer.active .bottom.frost-anim__item_7 {
  transition-delay: 0.2s !important;
  transform: translate(0, 0) scale(1);
}

/* Frost Additionals ------------------ */
.additional.frost-anim__item_2 {
  bottom: 0;
  right: -5%;
  width: 20%;
  transition-delay: var(--transSpeedOut) !important;
  transform: translate(2rem, 2rem) rotate(135deg) scale(var(--scaleice));
}

.frost-anim__layer.active .additional.frost-anim__item_2 {
  transform: translate(0, 0) rotate(135deg) scale(1.075);
  opacity: 0.75;
  transition-delay: 1s !important;
}

.additional.frost-anim__item_5 {
  top: 0;
  left: -5%;
  width: 20%;
  transition-delay: var(--transSpeedOut) !important;
  transform: translate(-2rem, -2rem) rotate(-45deg) scale(var(--scaleice));
}

.frost-anim__layer.active .additional.frost-anim__item_5 {
  transform: translate(0, 0) rotate(-45deg) scale(1.075);
  opacity: 0.75;
  transition-delay: 0.5s !important;
}

@media (max-width: 1920px) {
  .additional:is(.frost-anim__item_2, .frost-anim__item_5) {
    display: none !important;
  }
}
</style>
