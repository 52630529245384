<template>
  <div v-if="logoSrc">
    <img :src="logoSrc" :alt="comp + ' logo'" />
  </div>
  <div v-else>
    <img :src="defaultLogo" alt="Default logo" />
  </div>
</template>

<script>
import FitzekLogo from '@/assets/complogos/fitzekLogo.png'
import DroemerLogo from '@/assets/complogos/droemerLogo.svg'
import FitzekPrimeLogo from '@/assets/complogos/fitzekLogo_prime.png'
import DefaultLogo from '@/assets/complogos/fitzekLogo.png' // Add a default logo

export default {
  name: 'CompLogos',
  props: {
    comp: {
      type: String,
      required: true,
    },
  },
  computed: {
    logoSrc() {
      switch (this.comp) {
        case 'fitzek':
          return FitzekLogo
        case 'droemer':
          return DroemerLogo
        case 'fitzek_prime':
          return FitzekPrimeLogo
        default:
          return null // Return null if no match
      }
    },
    defaultLogo() {
      // Define the default logo as a computed property
      return DefaultLogo
    },
  },
}
</script>

<style scoped>
/* Add any styles you need for the company logos */
img {
  max-width: 100%;
  height: auto;
  display: block;
}
</style>
