<template>
  <div class="videosection py-0 lg:pb-10 lg:pt-16">
    <div
      class="grid container gap-12 mx-auto"
      :class="'grid-cols-' + videoArray.length"
    >
      <!-- Loop through the array of videos and render each one -->
      <div
        class="relative fCenter w-full"
        v-for="(video, index) in videoArray"
        :key="index"
      >
        <div class="video-wrapper">
          <!-- Conditionally render YouTube iframe or video tag based on videoLocal flag -->
          <div v-if="!video.videoLocal">
            <div :id="'player-' + index"></div>
          </div>
          <div class="self-hosted" v-else>
            <video
              :src="video.videoLink"
              controls
              playsinline
              :id="'local_player__' + index"
              @play="onLocalVideoPlay(index)"
              @pause="onLocalVideoPause(index)"
              @ended="onLocalVideoEnd(index)"
            ></video>
            <div
              :id="'local_player__overlay' + index"
              class="local_player__overlay active"
              @click="playLocalVideo(index)"
            >
              <img
                v-if="video.overlayImage"
                :id="'local_player__overlay_image_' + index"
                :src="video.overlayImage.url"
                :alt="video.overlayImage.alt"
                class="local_player__overlay_image"
              />
              <div class="local_player__overlay_ui">
                <img
                  src="https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/09/11155546/fitzek-play-button.svg"
                  alt="Play Video"
                />
                <h3
                  class="mt-5 local_player__overlay_title subHeadline text-light"
                >
                  {{ video.overlayText }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global YT */
import { useAudioState } from '@/composables/useAudioState.js'
import { useVideoPlaying } from '@/composables/useVideoPlaying.js'

export default {
  props: {
    block: Object,
  },
  data() {
    return {
      players: [],
    }
  },
  computed: {
    videoArray() {
      const data = this.block.attrs.data
      const videos = data.videos || [] // Access 'videos' array directly

      if (Array.isArray(videos)) {
        return videos.map((video) => ({
          videoLink: video.videolink,
          overlayText: video.overlaytext,
          showOverlay: video.show_overlay === '1',
          videoLocal: video.video_local === '1',
          overlayImage: video.overlay_image,
        }))
      }

      return videos
    },
  },
  mounted() {
    // Load the YouTube API script only if there are non-local videos
    if (this.videoArray.some((video) => !video.videoLocal)) {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }

    // Bind the API ready event to a global function
    window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady
  },
  methods: {
    onYouTubeIframeAPIReady() {
      this.videoArray.forEach((video, index) => {
        if (!video.videoLocal) {
          const player = new YT.Player(`player-${index}`, {
            height: '720',
            width: '1280',
            videoId: video.videoLink,
            events: {
              onStateChange: (event) => this.onPlayerStateChange(event, index),
              onError: (error) => this.onPlayerError(error, index),
            },
          })
          this.players.push(player)
        }
      })
    },
    onPlayerStateChange(event) {
      const { state } = useAudioState()
      const { videoState } = useVideoPlaying()

      if (event.data == YT.PlayerState.PLAYING) {
        videoState.videoIsPlaying = true
        state.isMuted = false
      } else if (
        event.data == YT.PlayerState.ENDED ||
        event.data == YT.PlayerState.PAUSED
      ) {
        videoState.videoIsPlaying = false
        state.isMuted = state.isClicked ? true : false
      }
    },
    playLocalVideo(index) {
      // Select the video element and the overlay
      const videoElement = document.getElementById(`local_player__${index}`)
      const overlayElement = document.getElementById(
        `local_player__overlay${index}`
      )

      // Remove the 'active' class from the overlay
      if (overlayElement) {
        overlayElement.classList.remove('active')
      }

      // Play the video
      if (videoElement) {
        videoElement.play()
      }
    },
    onLocalVideoPlay() {
      const { state } = useAudioState()
      const { videoState } = useVideoPlaying()

      videoState.videoIsPlaying = true
      state.isMuted = false
    },
    onLocalVideoPause() {
      const { state } = useAudioState()
      const { videoState } = useVideoPlaying()

      videoState.videoIsPlaying = false
      state.isMuted = state.isClicked ? true : false
    },
    onLocalVideoEnd() {
      const { state } = useAudioState()
      const { videoState } = useVideoPlaying()

      videoState.videoIsPlaying = false
      state.isMuted = state.isClicked ? true : false
    },
    onPlayerError(error, index) {
      if (error.data === 150) {
        console.error(
          `Error with video ${index}: This video is private or restricted.`
        )

        // Add .hide class to the related video-wrapper
        const videoWrapper = document.querySelector(
          `.video-wrapper:nth-of-type(${index + 1})`
        )
        if (videoWrapper) {
          videoWrapper.classList.add('hide')
        }

        const videosectionWrapper = document.querySelector('.videosection')
        if (videosectionWrapper) {
          videosectionWrapper.classList.add('hide')
        }
      } else {
        console.error(`Error with video ${index}:`, error.data)
        // Handle other types of errors
      }
    },
  },
}
</script>

<style>
.video-wrapper {
  width: 100%;
  max-width: 850px;
}

.video-wrapper iframe,
.video-wrapper video {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  border-radius: 5px;
  border: 4px solid #fff;
}

.videosection.hide {
  display: none !important;
}

.videosection .overlaytext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
}

.self-hosted {
  position: relative;
}

.local_player__overlay {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  inset: 4px;
  z-index: -1;
  cursor: pointer;
  isolation: isolate;
}

.local_player__overlay.active {
  z-index: 2;
}

.local_player__overlay .local_player__overlay_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
}

.local_player__overlay_ui {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.local_player__overlay_ui img {
  max-width: 4rem;
}

.local_player__overlay_title {
  margin-bottom: 0 !important;
}

.video-wrapper.hide {
  display: none !important;
}
</style>
