<template>
  <div class="social-icons fyCenter">
    <a
      v-for="(item, index) in socialMediaLinks"
      :key="index"
      :href="item.link"
      target="_blank"
      class="mx-2"
      rel="noopener noreferrer"
    >
      <div v-html="getIcon(item.code)"></div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialIcons',
  props: {
    comp: {
      type: String,
      required: true,
    },
  },
  computed: {
    socialMediaLinks() {
      switch (this.comp) {
        case 'fitzek':
          return this.$root.acfOptions.soccontact.socmedfitzek
        case 'droemer':
          return this.$root.acfOptions.soccontact.socmeddroemer
        default:
          return []
      }
    },
  },
  methods: {
    getIcon(code) {
      switch (code) {
        case 'fb':
          return `
              <svg id="Group_412" data-name="Group 412" xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58">
  <g id="Ellipse_1" data-name="Ellipse 1" fill="none" stroke="#be2828" stroke-width="3">
    <circle cx="29" cy="29" r="29" stroke="none"/>
    <circle cx="29" cy="29" r="27.5" fill="none"/>
  </g>
  <path id="Path_442" data-name="Path 442" d="M89.962,29.4V16.005h4.573l.653-5.226H89.962V7.513c0-1.47.49-2.613,2.613-2.613h2.776V.163C94.7.163,93.066,0,91.269,0c-3.92,0-6.7,2.45-6.7,6.859v3.92H80v5.226h4.573V29.4Z" transform="translate(-58.855 14.122)" fill="#f8f4f4" fill-rule="evenodd"/>
</svg>
            `
        case 'ig':
          return `
            <svg xmlns="http://www.w3.org/2000/svg" width="57" height="58" viewBox="0 0 57 58">
  <g id="Group_413" data-name="Group 413" transform="translate(-0.414)">
    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0.414)" fill="none" stroke="#be2828" stroke-width="3">
      <ellipse cx="28.5" cy="29" rx="28.5" ry="29" stroke="none"/>
      <ellipse cx="28.5" cy="29" rx="27" ry="27.5" fill="none"/>
    </g>
    <path id="Path_443" data-name="Path 443" d="M15.647,2.782a47.979,47.979,0,0,1,6.259.174,8.064,8.064,0,0,1,2.956.522,6.106,6.106,0,0,1,2.956,2.956,8.064,8.064,0,0,1,.522,2.956c0,1.565.174,2.086.174,6.259a47.978,47.978,0,0,1-.174,6.259,8.064,8.064,0,0,1-.522,2.956,6.106,6.106,0,0,1-2.956,2.956,8.064,8.064,0,0,1-2.956.522c-1.565,0-2.086.174-6.259.174a47.978,47.978,0,0,1-6.259-.174,8.064,8.064,0,0,1-2.956-.522,6.106,6.106,0,0,1-2.956-2.956,8.064,8.064,0,0,1-.522-2.956c0-1.565-.174-2.086-.174-6.259a47.979,47.979,0,0,1,.174-6.259,8.064,8.064,0,0,1,.522-2.956A6.244,6.244,0,0,1,4.694,4.694,2.939,2.939,0,0,1,6.433,3.477a8.064,8.064,0,0,1,2.956-.522,47.979,47.979,0,0,1,6.259-.174m0-2.782A51.371,51.371,0,0,0,9.215.174a10.733,10.733,0,0,0-3.825.7A6.806,6.806,0,0,0,2.608,2.608,6.806,6.806,0,0,0,.869,5.39a7.922,7.922,0,0,0-.7,3.825A51.371,51.371,0,0,0,0,15.647,51.371,51.371,0,0,0,.174,22.08a10.733,10.733,0,0,0,.7,3.825,6.806,6.806,0,0,0,1.739,2.782A6.806,6.806,0,0,0,5.39,30.426a10.733,10.733,0,0,0,3.825.7,51.374,51.374,0,0,0,6.433.174,51.374,51.374,0,0,0,6.433-.174,10.733,10.733,0,0,0,3.825-.7,7.294,7.294,0,0,0,4.52-4.52,10.733,10.733,0,0,0,.7-3.825c0-1.739.174-2.26.174-6.433a51.374,51.374,0,0,0-.174-6.433,10.733,10.733,0,0,0-.7-3.825,6.806,6.806,0,0,0-1.739-2.782A6.806,6.806,0,0,0,25.905.869a10.733,10.733,0,0,0-3.825-.7A51.371,51.371,0,0,0,15.647,0m0,7.65a7.869,7.869,0,0,0-8,8,8,8,0,1,0,8-8m0,13.213a5.123,5.123,0,0,1-5.216-5.216,5.123,5.123,0,0,1,5.216-5.216,5.123,5.123,0,0,1,5.216,5.216,5.123,5.123,0,0,1-5.216,5.216M23.993,5.39A1.912,1.912,0,1,0,25.905,7.3,1.929,1.929,0,0,0,23.993,5.39" transform="translate(13.173 13.173)" fill="#f8f4f4" fill-rule="evenodd"/>
  </g>
</svg>

            `
        case 'yt':
          return `
      <svg xmlns="http://www.w3.org/2000/svg" width="57" height="58" viewBox="0 0 57 58">
  <g id="Group_4237" data-name="Group 4237" transform="translate(-1230 -3511.999)">
    <g id="Group_3884" data-name="Group 3884" transform="translate(1229.586 3511.999)">
      <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0.414)" fill="none" stroke="#be2828" stroke-width="3">
        <ellipse cx="28.5" cy="29" rx="28.5" ry="29" stroke="none"/>
        <ellipse cx="28.5" cy="29" rx="27" ry="27.5" fill="none"/>
      </g>
    </g>
    <path id="Path_5873" data-name="Path 5873" d="M335.663-11.71a4.367,4.367,0,0,0-3.1-3.078c-2.743-.714-13.716-.714-13.716-.714s-10.772-.045-13.515.669a4.8,4.8,0,0,0-3.368,3.122c-.736,2.721-.669,8.363-.669,8.363s-.067,5.643.669,8.363a4.841,4.841,0,0,0,3.368,3.122c2.743.736,13.515.669,13.515.669s10.772.045,13.515-.669a4.735,4.735,0,0,0,3.323-3.122c.736-2.7.736-8.363.736-8.363A45.375,45.375,0,0,0,335.663-11.71ZM315.479,2.05V-8.744l8.765,5.4Z" transform="translate(940.045 3543.945)" fill="#f8f4f4"/>
  </g>
</svg>

            `
        case 'tt':
          return `
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="58" viewBox="0 0 57 58">
  <g id="Group_6558" data-name="Group 6558" transform="translate(-1075 -5407.727)">
    <path id="bxl-tiktok" d="M25.354,8.339a6.528,6.528,0,0,1-5.1-5.745V2H15.6V20.477a3.915,3.915,0,1,1-3.906-3.92,3.96,3.96,0,0,1,1.189.176V12a9.245,9.245,0,0,0-1.352-.068,8.543,8.543,0,1,0,8.786,8.542V11.016A11.029,11.029,0,0,0,26.76,13.07v-4.6a6.556,6.556,0,0,1-1.352-.135Z" transform="translate(1088.521 5421.214)" fill="#f8f4f4"/>
    <g id="Group_6557" data-name="Group 6557" transform="translate(-155 1895.728)">
      <g id="Group_3884" data-name="Group 3884" transform="translate(1229.586 3511.999)">
        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(0.414)" fill="none" stroke="#be2828" stroke-width="3">
          <ellipse cx="28.5" cy="29" rx="28.5" ry="29" stroke="none"/>
          <ellipse cx="28.5" cy="29" rx="27" ry="27.5" fill="none"/>
        </g>
      </g>
    </g>
  </g>
</svg>            `
        default:
          return null
      }
    },
  },
}
</script>

<style scoped>
/* Add any styles you need for the social icons */
</style>
