<template>
  <div class="preloaderWrapper">
    <div id="preloader">
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          id="vertical"
          class="tframe"
          x1="100"
          y1="90"
          x2="100"
          y2="180"
          stroke="black"
          stroke-width="6"
        />
        <line
          id="horizontal"
          class="tframe"
          x1="50"
          y1="90"
          x2="150"
          y2="90"
          stroke="black"
          stroke-width="6"
        />
        <rect
          id="left"
          class="windowframe"
          x="50"
          y="20"
          width="10"
          height="160"
          stroke="black"
          stroke-width="4"
          fill="none"
        />
        <rect
          id="right"
          class="windowframe"
          x="140"
          y="20"
          width="10"
          height="160"
          stroke="black"
          stroke-width="4"
          fill="none"
        />
        <rect
          id="top"
          class="windowframe"
          x="50"
          y="20"
          width="100"
          height="10"
          stroke="black"
          stroke-width="4"
          fill="none"
        />
        <rect
          id="bottom"
          class="windowframe"
          x="50"
          y="170"
          width="100"
          height="10"
          stroke="black"
          stroke-width="4"
          fill="none"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>
<style>
@keyframes lost-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(0.85);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
#preloader svg {
  display: block;
  margin: auto;
  width: 500px;
  height: 500px;
  max-width: 80%;
  max-height: 80%;
  animation: lost-effect 3s infinite ease-in-out;
}

.windowframe {
  stroke: none;
  fill: #be2828;
}

.tframe {
  stroke: #811414;
}
</style>
