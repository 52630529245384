<template>
  <div class="raffle__inner_wrapper shadows-y">
    <div
      class="container-fluid text-left bg-cover bg-center"
      :style="{
        backgroundImage:
          block.attrs.data.bgimg && block.attrs.data.bgimg.url
            ? `url(${block.attrs.data.bgimg.url})`
            : '',
      }"
    >
      <div
        class="container min-h-screen mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-16"
      >
        <div class="flex items-center col-span-1 md:pr-10">
          <div>
            <div class="secInd col-span-1 md:col-span-2 mb-8">
              {{ block.attrs.data.secInd }}
            </div>
            <h2 class="bigHeadline text-light">
              {{ block.attrs.data.title }}
            </h2>
            <div
              class="text-base subHeadline mw-sm mb-8"
              v-html="block.attrs.data.subtitle"
            ></div>
            <btn-comp
              v-if="block.attrs.data.basicCTA != 1"
              :name="block.attrs.data.ctalabel"
              @click="modalState.winOverlay = true"
              :block="block.attrs.data.btn"
              class="challengeBtn"
              :hasicon="false"
            />
            <btn-comp
              v-else
              :btntype="'a'"
              :name="block.attrs.data.ctalabel"
              :href="block.attrs.data.ctalink"
              :block="block.attrs.data.btn"
              :ctaicon="block.attrs.data.ctaicon.url"
              class="w-fit"
              :class="
                block.attrs.data.ctavariant === '0' ||
                block.attrs.data.ctavariant === null ||
                !block.attrs.data.ctavariant
                  ? 'outbound_cta icon'
                  : ''
              "
              :hasicon="block.attrs.data.ctavariant === '1' ? true : false"
              :variant="
                block.attrs.data.ctavariant === '1' ? 'outline' : 'prime'
              "
            />
          </div>
        </div>

        <div
          class="flex justify-center items-center col-span-1 relative mt-20 lg:mt-0"
        >
          <div class="relative">
            <img
              v-if="
                block?.attrs?.data?.fgimg?.url != '' &&
                !block?.attrs?.data?.fgvideo
              "
              :src="block.attrs.data.fgimg.url"
              alt=""
            />
            <video
              v-if="block?.attrs?.data?.fgvideo"
              :src="block.attrs.data.fgvideo"
              muted
              loop
              autoplay
              playsinline
              class="w-full"
            ></video>
            <img
              v-if="block?.attrs?.data?.badgeimg?.url != ''"
              :src="block.attrs.data.badgeimg.url"
              alt=""
              class="section_badge absolute pointer-events-none"
            />
          </div>
        </div>

        <v-modal
          v-if="modalState.winOverlay"
          width="sm"
          max-width="md"
          v-on:close="modalState.winOverlay = false"
        >
          <div v-if="!formSuccess">
            <div class="text-center">
              <h2 class="bigHeadline text-light">
                {{ block.attrs.data.overlay_title }}
              </h2>
              <p class="text-base lg:text-lg mb-4">
                {{ block.attrs.data.overlay_desc }}
              </p>
              <div v-show="!loading">
                <div class="grid grid-cols-2 gap-6 mw-md mx-auto pt-4">
                  <div :class="{ txtInput: true, error: !usernameValid }">
                    <input
                      type="text"
                      name="name"
                      placeholder="Vorname Name *"
                      autocomplete="off"
                      v-model="username"
                      @input="validateField('username')"
                    />
                  </div>
                  <div :class="{ txtInput: true, error: !emailValid }">
                    <input
                      type="email"
                      name="email"
                      autocomplete="off"
                      placeholder="E-Mail Adresse *"
                      v-model="email"
                      @input="validateField('email')"
                    />
                  </div>
                </div>
                <div
                  :class="{ uploadInput: true, error: !fileValid }"
                  class="pt-8 mw-lg mx-auto"
                >
                  <div class="uploadWrap" :class="{ hasimg: filePreview }">
                    <input
                      type="file"
                      ref="fileInput"
                      @change="handleFileChange"
                    />
                    <div v-if="filePreview" class="file-preview">
                      <img
                        v-if="isImage"
                        :src="filePreview"
                        alt="Preview"
                        class="preview-image"
                      />
                      <span v-else class="preview-text"
                        >File: {{ fileName }}</span
                      >
                      <button @click="removeFile" class="remove-file-button">
                        entfernen
                      </button>
                    </div>
                    <div v-else class="uploadCont text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="59.9"
                        height="59.9"
                        class="mx-auto mb-4"
                        viewBox="0 0 59.9 59.9"
                      >
                        <path
                          id="uploadsvg"
                          d="M206.735,190.08a16.668,16.668,0,0,0-16.705,16.705v26.489a16.668,16.668,0,0,0,16.705,16.705h26.489a16.67,16.67,0,0,0,16.705-16.705V206.785a16.67,16.67,0,0,0-16.705-16.705Zm-1.116,18.092h0a4.963,4.963,0,1,1-3.51,1.455,4.967,4.967,0,0,1,3.51-1.455Zm27.239,11.849a3.148,3.148,0,0,1,2.092.808l11.729,12.917a13.362,13.362,0,0,1-13.452,13l-26.489,0a13.354,13.354,0,0,1-13.278-11.2l15.192-8.771a4.27,4.27,0,0,1,4.685.274l4.394,3.267a3.313,3.313,0,0,0,4.353-.185l8.681-9.322a3.134,3.134,0,0,1,2.1-.791Z"
                          transform="translate(-190.03 -190.08)"
                          fill="#f8f4f4"
                        />
                      </svg>

                      <div class="font-bold">
                        Lade dein Fenster-Foto hoch <br />
                        (drag&drop oder
                        <span class="text-primary"> click</span>)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkMarkWrapper mw-md mx-auto pt-2">
                  <div
                    :class="{ checkPrivacyWrapper: true, error: !dsgvoValid }"
                  >
                    <input
                      id="dsgvo"
                      type="checkbox"
                      v-model="dsgvo"
                      @change="validateField('dsgvo')"
                    />
                    <label for="dsgvo">
                      <span></span>
                      <div class="gsdvoTxt">
                        Ja, die
                        <a
                          href="https://fitzek-kalendermaedchen.de/datenschutz"
                          target="_blank"
                          >Datenschutzhinweise</a
                        >
                        habe ich zur Kenntnis genommen und ich akzeptiere diese.
                      </div>
                    </label>
                  </div>
                  <div
                    :class="{
                      checkPrivacyWrapper: true,
                      error: !teilnahmeValid,
                    }"
                  >
                    <input
                      id="teilnahme"
                      type="checkbox"
                      v-model="teilnahme"
                      @change="validateField('teilnahme')"
                    />
                    <label for="teilnahme">
                      <span></span>
                      <div class="gsdvoTxt">
                        Ja, ich habe die
                        <a
                          href="https://fitzek-kalendermaedchen.de/teilnahmebedingungen"
                          target="_blank"
                          >Teilnahmebedingungen</a
                        >
                        gelesen und bestätige hiermit meine Einwilligung.
                      </div>
                    </label>
                  </div>
                </div>
                <div class="btnCompWrapper fCenter pt-8">
                  <btn-comp
                    id="submitWindow"
                    name="Abschicken"
                    @click="handleSubmit"
                    :disabled="loading"
                  />
                </div>
              </div>
              <div v-if="loading" class="loading-indicator">
                <preloaderComp
                  width="10"
                  height="160"
                  stroke="black"
                  stroke-width="4"
                  fill="none"
                ></preloaderComp>
                {{ loadingMessage }}
              </div>
            </div>
          </div>
          <div v-else-if="formSuccess">
            <div class="text-center w-full">
              <h2 class="bigHeadline text-light">
                {{ block.attrs.data.overlay_title }}
              </h2>
              <div class="">Ich habe dein Fenster erhalten!</div>
            </div>
          </div>
        </v-modal>
      </div>
    </div>
  </div>
</template>

<script>
import BtnComp from '../framework/btnComp.vue'
import VModal from '../framework/v-modal.vue'
import axios from 'axios'
import PreloaderComp from '../framework/preloaderComp.vue'
import { useModal } from '@/composables/useModal.js'

export default {
  components: {
    VModal: VModal,
    btnComp: BtnComp,
    preloaderComp: PreloaderComp,
  },
  props: {
    block: Object,
  },
  data() {
    return {
      // winOverlay: false,
      username: '',
      email: '',
      dsgvo: false,
      teilnahme: false,
      usernameValid: true,
      emailValid: true,
      fileValid: true,
      dsgvoValid: true,
      teilnahmeValid: true,
      filePreview: null,
      fileName: '',
      isImage: false,
      loading: false,
      loadingMessage: 'Bitte warten...',
      formSuccess: false,
    }
  },
  setup() {
    const { modalState } = useModal()
    return { modalState }
  },
  methods: {
    validateField(field) {
      if (field === 'username') {
        this.usernameValid = !!this.username.trim()
      }
      if (field === 'email') {
        this.emailValid =
          !!this.email.trim() && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)
      }
      if (field === 'dsgvo') {
        this.dsgvoValid = this.dsgvo
      }
      if (field === 'teilnahme') {
        this.teilnahmeValid = this.teilnahme
      }
      if (field === 'file') {
        this.fileValid = this.checkFileUploaded()
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > 10 * 1024 * 1024) {
          // 10 MB size limit
          alert(
            'Die Dateigröße überschreitet das Limit von 10 MB. Bitte wähle eine kleinere Datei.'
          )
          this.removeFile()
          return
        }
        this.fileName = file.name
        this.isImage = file.type.startsWith('image/')
        if (this.isImage) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.filePreview = e.target.result
          }
          reader.readAsDataURL(file)
        } else {
          this.filePreview = null
          alert(
            'Die ausgewählte Datei ist kein Bild. Bitte wähle ein Bild aus.'
          )
        }
        this.validateField('file')
      }
    },
    removeFile() {
      this.filePreview = null
      this.fileName = ''
      this.isImage = false
      this.fileValid = false
      this.$refs.fileInput.value = null // Reset the file input
    },
    async handleSubmit() {
      this.validateField('username')
      this.validateField('email')
      this.validateField('dsgvo')
      this.validateField('teilnahme')
      this.validateField('file')

      if (
        this.usernameValid &&
        this.emailValid &&
        this.dsgvoValid &&
        this.teilnahmeValid &&
        this.fileValid
      ) {
        this.loading = true
        const formData = new FormData()
        formData.append('username', this.username)
        formData.append('email', this.email)
        formData.append('file', this.$refs.fileInput.files[0]) // Upload file

        try {
          // Send POST request to PHP backend
          const response = await axios.post(
            'https://fitzek-kalendermaedchen.de/upload_attendee.php',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )

          if (response.data.success) {
            this.resetForm() // Reset form on success
          } else {
            throw new Error(response.data.error || 'Submission failed')
          }
        } catch (error) {
          console.error('Error:', error)
          alert(
            'Beim Absenden ist ein Fehler aufgetreten. Bitte versuche es erneut.'
          )
        } finally {
          this.loading = false
        }
      } else {
        alert('Bitte fülle alle erforderlichen Felder korrekt aus.')
      }
    },

    checkFileUploaded() {
      const fileInput = this.$refs.fileInput
      return fileInput && fileInput.files && fileInput.files.length > 0
    },
    resetForm() {
      this.username = ''
      this.email = ''
      this.dsgvo = false
      this.teilnahme = false
      this.usernameValid = true
      this.emailValid = true
      this.fileValid = true
      this.dsgvoValid = true
      this.teilnahmeValid = true
      this.filePreview = null
      this.fileName = ''
      this.isImage = false
      this.$refs.fileInput.value = '' // Reset the file input
      this.formSuccess = true
    },
  },
}
</script>

<style>
.checkPrivacyWrapper input[type='checkbox'] {
  height: 0;
  width: 0;
  opacity: 0;
}
.checkPrivacyWrapper input[type='checkbox'] + label {
  position: relative;
  display: flex;
  margin: 0.6em 0;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkPrivacyWrapper .gsdvoTxt {
  padding-top: 4px;
}
.checkPrivacyWrapper input[type='checkbox'] + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkPrivacyWrapper input[type='checkbox'] + label:hover,
.checkPrivacyWrapper input[type='checkbox']:focus + label {
  color: #fff;
}
.checkPrivacyWrapper input[type='checkbox'] + label:hover > span,
.checkPrivacyWrapper input[type='checkbox']:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}
.checkPrivacyWrapper input[type='checkbox']:checked + label > span {
  border: 15px solid;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
  @apply border-light;
}
.checkPrivacyWrapper input[type='checkbox']:checked + label > span:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 7px;
  width: 5px;
  height: 10px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}
.file-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.preview-image {
  @apply w-52 aspect-square object-contain my-4;
}
.file-preview {
  @apply h-auto w-52 my-4;
}

.loading-indicator .preloaderWrapper {
  position: relative;
}

.remove-file-button {
  background-color: var(--cRed);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}
.error {
  border: 1px solid var(--cRed);
  color: var(--cRed);
}
.checkPrivacyWrapper.error,
.uploadInput.error {
  border: none;
}
.uploadInput.error .uploadWrap {
  border-color: var(--cRed);
}
.txtInput.error input,
.checkPrivacyWrapper.error label,
.uploadInput.error input {
  border-color: var(--cRed);
}
.loading-indicator {
  margin-top: 1rem;
  color: var(--cRed);
  font-weight: bold;
}

/* Keyframes for animations */
@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: transparent;
    transform: rotate(45deg) scale(0);
  }
  100% {
    width: 8px;
    height: 15px;
    transform: rotate(45deg) scale(1);
    border-color: #000;
  }
}

.section_badge {
  width: 100%;
  max-width: 500px;
  top: -12rem;
  right: -6rem;
}

@media (max-width: 1300px) {
  .section_badge {
    max-width: 400px;
    top: -12rem;
    right: -3rem;
  }
}

@media (max-width: 1200px) {
  .raffle__inner_wrapper h2 {
    word-break: break-all;
  }
}

@media (max-width: 992px) {
  .section_badge {
    max-width: 300px;
    top: -8rem;
  }
}

@media (max-width: 768px) {
  .section_badge {
    top: -6rem;
  }
}

@media (max-width: 576px) {
  .raffle__inner_wrapper {
    overflow-x: hidden;
  }

  .section_badge {
    right: -3rem;
    top: -9rem;
  }
}
</style>
