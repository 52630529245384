<template>
  <div class="btnCompWrapper">
    <component
      :is="btntype"
      v-if="variant !== 'nav'"
      :class="['btnComp btnCTA', 'btnComp--' + variant, btnclass]"
      :href="btntype === 'a' ? href : undefined"
      :to="btntype !== 'a' ? href : undefined"
      :target="
        btntype === 'a' && href && !href.startsWith('#') ? '_blank' : undefined
      "
    >
      <img v-if="ctaicon" :src="ctaicon" alt="" />

      <div v-html="name"></div>

      <svg
        v-if="hasicon"
        xmlns="http://www.w3.org/2000/svg"
        width="9.758"
        height="16.687"
        viewBox="0 0 9.758 16.687"
      >
        <path
          id="Path_440"
          data-name="Path 440"
          d="M629.532,520.334l7.636,7.636-7.636,7.636"
          transform="translate(-628.824 -519.627)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </svg>
    </component>
    <component
      :is="btntype"
      v-else
      :class="['btnComp btnNav', 'btnComp--' + variant, btnclass]"
      :href="btntype === 'a' ? href : undefined"
      :to="btntype !== 'a' ? href : undefined"
    >
      {{ name }}
    </component>
  </div>
</template>

<script>
export default {
  name: 'BtnComp',
  props: {
    variant: {
      type: String,
      default: 'prime',
      validator: function (value) {
        // The value must match one of these strings
        return ['prime', 'outline', 'nav'].includes(value)
      },
    },
    btntype: {
      type: String,
      default: 'button',
      validator: function (value) {
        // The value must match one of these strings
        return ['button', 'a', 'div'].includes(value)
      },
    },
    name: {
      type: String,
      default: 'Klicken',
    },
    href: {
      type: String,
      default: '',
    },
    btnclass: {
      type: String,
      default: '',
    },
    hasicon: {
      type: Boolean,
      default: true,
    },
    ctaicon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
