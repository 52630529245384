<template>
  <div class="name py-32">
    <div class="container">
      <div class="content" v-html="content.content.rendered"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
<style>
.content h1 {
  @apply text-6xl mt-4 mb-2;
}
.content h2 {
  @apply text-4xl mt-4 mb-2;
}
.content h3 {
  @apply text-3xl mt-4 mb-2;
}
.content h4 {
  @apply text-2xl mt-4 mb-2;
}
</style>
