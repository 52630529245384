import { reactive, watch } from 'vue'

// Singleton state
const state = reactive({
  isMuted: false, // Whether the sound is muted or not
  isPlaying: false, // Whether the general sound is playing
  isClicked: false, // Whether the sound button is clicked
})

export const useAudioState = () => {
  // Primarily used in the sound button
  const toggleClicked = () => {
    state.isClicked = !state.isClicked
  }

  watch(
    () => state.isMuted,
    (newValue) => {
      state.isMuted = newValue
    }
  )

  return {
    state,
    toggleClicked,
  }
}
