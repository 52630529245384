<template>
  <div v-if="shop.shop_show" class="container-fluid">
    <div class="container">
      <div class="shop__wrapper grid grid-cols-5">
        <div
          class="col-span-5 md:col-span-2 flex flex-col justify-center items-start"
        >
          <div class="secInd">{{ shop.shop_indicator }}</div>
          <h2 class="text-light bigHeadline" v-html="shop.shop_title"></h2>
          <btn-comp
            :name="shop.shop_button.title"
            variant="outline"
            btntype="a"
            :href="shop.shop_button.url"
            target="_blank"
            class="mt-5 w-fit"
          />
        </div>
        <div
          class="col-span-5 md:col-span-3 -mt-20 md:mt-0 pointer-events-none"
        >
          <img :src="shop.shop_image.url" :alt="shop.shop_image.alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
// Components
import btnComp from '@/components/framework/btnComp.vue'

const acfOptions = defineProps({
  acfOptions: Object,
})

const shop = ref(acfOptions.acfOptions.shop)
</script>
