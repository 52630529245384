<template>
  <div
    class="heroCont container fCenter fyEnd text-center pb-10 md:pb-8 3xl:pb-32"
  >
    <div
      class="heroBg heroBgDesk"
      :style="{
        backgroundImage: `url(${getImageUrl(block.attrs.data.herodesk)})`,
      }"
    ></div>
    <div
      class="heroBg heroBgMobile"
      :style="{
        backgroundImage: `url(${getImageUrl(block.attrs.data.heromobile)})`,
      }"
    ></div>
    <div class="pb-hero">
      <div class="secInd">
        {{ block.attrs.data.secInd || 'Section Indicator' }}
      </div>
      <h2
        class="bigHeadline text-white"
        v-html="formatContent(block.attrs.data.title || 'Default Title')"
      ></h2>
      <p
        class="subHeadline"
        v-html="
          formatContent(
            block.attrs.data.subtitle || 'Default subtitle content.'
          )
        "
      ></p>
      <div class="fCenter">
        <btn-comp
          variant="outline"
          btntype="a"
          btnclass=""
          href="#bestellen"
          name="BUCH VORBESTELLEN"
          @click.prevent="handleNavigation('#bestellen')"
        />
      </div>
    </div>

    <div class="heroBadge" v-if="block.attrs.data.hasbadge == '1'">
      <div class="cursor-pointer" @click="handleNavigation('#challenge')">
        <img
          :src="getImageUrl(block.attrs.data.badgeimg)"
          :alt="block.attrs.data.badgeimg.alt || 'Badge Image'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useNavigation } from '@/composables/useNavigation'
import btnComp from '@/components/framework/btnComp.vue'

export default {
  components: { btnComp },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { handleNavigation } = useNavigation()

    return {
      handleNavigation,
    }
  },
  methods: {
    formatContent(content) {
      // Replace \n with <br> for HTML line breaks
      if (content) {
        return content.replace(/\n/g, '<br>')
      }
      return 'Content not available.'
    },
    getImageUrl(imageData) {
      // Debugging log for imageData
      // console.log(`Fetching ${type} image: `, imageData)

      // Ensure we correctly extract the URL from Vue Proxy object
      return imageData && imageData.url
        ? imageData.url
        : 'https://fitzek-kalender-public.s3.eu-central-1.amazonaws.com/wp-content/uploads/2024/07/24134437/footerBg.webp'
    },
  },
}
</script>

<style>
.heroBg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
}
.heroBgDesk,
.heroBgMobile {
  display: none;
}
.pb-hero {
  padding-bottom: 0;
}
.heroCont .subHeadline {
  margin-bottom: 4px;
  @apply md:mb-6;
}

.heroBadge {
  position: absolute;
  top: 5vw;
  width: 30vw;
  height: 1/1;
  aspect-ratio: 1/1;
  right: 10vw;
}

@media (orientation: landscape) {
  .heroBadge {
    top: 0;
  }
  .heroBgDesk {
    display: block !important; /* Ensure it is displayed in landscape */
  }
}
@media (orientation: portrait) {
  .heroBadge {
    right: 0vw;
    top: 17vw;
    width: 60vw;
  }
  .heroBgMobile {
    display: block !important; /* Ensure it is displayed in portrait */
  }
}

@media (max-width: 576px) {
  .pb-hero {
    padding-bottom: 11rem;
  }

  .heroCont .subHeadline {
    margin-bottom: 16px;
    @apply md:mb-12;
  }
}

@media (max-width: 390px) {
  .pb-hero {
    padding-bottom: 3rem;
  }
}

@media (max-width: 360px) {
  .pb-hero {
    padding-bottom: 2rem;
  }
}
</style>
