// src/composables/useNavigation.js

import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export function useNavigation() {
  const router = useRouter()
  const route = useRoute()
  const isActive = ref(false)

  const showPreloader = () => {
    // Implement preloader logic, e.g., showing a spinner
  }

  const hidePreloader = () => {
    // Implement hide preloader logic, e.g., hiding the spinner
  }

  const scrollToHash = (hash) => {
    const element = document.querySelector(hash)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleNavigation = (link) => {
    if (link.startsWith('#')) {
      if (route.path === '/') {
        scrollToHash(link)
      } else {
        router.push('/').then(() => {
          scrollToHash(link)
        })
      }
    } else {
      isActive.value = false
      document.body.classList.remove('navOpen')

      showPreloader()
      router.push(link).finally(hidePreloader)
    }
  }

  return { handleNavigation }
}
