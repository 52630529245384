import { reactive } from 'vue'

// Singleton state
const videoState = reactive({
  videoIsPlaying: false,
})

export const useVideoPlaying = () => {
  return {
    videoState,
  }
}
