<template>
  <div class="shadows-y">
    <div
      v-if="block.imgDetails"
      class="container-fluid text-left bg-cover bg-center"
      :style="{
        backgroundImage: `url(${block.imgDetails.source_url})`,
      }"
    >
      <div
        class="container min-h-screen mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-16"
      >
        <div></div>
        <div class="flex items-center col-span-1">
          <div>
            <div class="secInd col-span-1 md:col-span-2 mb-8">
              {{ block.attrs.data.secInd }}
            </div>
            <h2 class="bigHeadline text-light">
              {{ block.attrs.data.title }}
            </h2>
            <p class="text-base subHeadline mb-4">
              {{ block.attrs.data.desc }}
            </p>
            <div v-if="block.attrs.data.window_cta == 1">
              <button
                class="btnComp btnCTA btnComp--outline"
                @click="modalState.winOverlay = true"
              >
                <div>FOTO HOCHLADEN UND GEWINNEN</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.758"
                  height="16.687"
                  viewBox="0 0 9.758 16.687"
                >
                  <path
                    id="Path_440"
                    data-name="Path 440"
                    d="M629.532,520.334l7.636,7.636-7.636,7.636"
                    transform="translate(-628.824 -519.627)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              v-if="
                block.attrs.data.show_basic_cta == 1 &&
                block.attrs.data.window_cta != 1
              "
            >
              <a
                class="btnComp btnCTA btnComp--outline w-fit"
                :href="block.attrs.data.show_basic_cta_link"
                target="_blank"
              >
                <div>JETZT AUF INSTAGRAM VOTEN</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.758"
                  height="16.687"
                  viewBox="0 0 9.758 16.687"
                >
                  <path
                    id="Path_440"
                    data-name="Path 440"
                    d="M629.532,520.334l7.636,7.636-7.636,7.636"
                    transform="translate(-628.824 -519.627)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-16"
      >
        <div></div>
        <div class="flex items-center">
          <div class="secInd col-span-1 md:col-span-2 mb-8">
            {{ block.attrs.data.secInd }}
          </div>
          <div class="col-span-1">
            <h2 class="bigHeadline text-lg lg:text-2xl font-bold mb-4">
              {{ block.attrs.data.title }}
            </h2>
            <p class="text-base lg:text-lg mb-4">
              {{ block.attrs.data.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useModal } from '@/composables/useModal.js'

export default {
  props: {
    block: Object,
  },
  setup() {
    const { modalState } = useModal()
    return { modalState }
  },
}
</script>

<style scoped>
.container-fluid {
  min-height: 100vh; /* Ensures the container covers the full viewport height */
}
</style>
