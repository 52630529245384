<template>
  <div id="appfi">
    <div class="previewBadge" v-if="!loading && isPeripheral == 'preview'">
      <div class="bigHeadline">
        <h2 class="cRed">Preview {{ previewLabel }}</h2>
      </div>
    </div>

    <ice-comp
      v-if="!loading && (isPeripheral == 'preview' || isPeripheral == '')"
      :acf-options="acfOptions"
      class=""
    />
    <Preloader
      v-if="loading"
      class="preloaderWrapper bg-dark"
      :class="{ finish: !loading }"
    />
    <NavBar v-if="!loading && navigation !== null" :navigation="navigation" />
    <router-view
      v-if="!loading && (isPeripheral == 'preview' || isPeripheral == '')"
      :acf-options="acfOptions"
      :landing-page="landingPage"
    />
    <router-view v-if="!loading && isPeripheral" :content="legalPage" />
    <Footer v-if="!loading && showFooter" />
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import { reactive, toRefs, onMounted, computed, watch } from 'vue'
import NavBar from '@/components/framework/headerComp.vue'
import Footer from '@/components/framework/footerComp.vue'
import Preloader from '@/components/framework/preloaderComp.vue'
import { useRoute } from 'vue-router'
import IceComp from './components/framework/iceComp.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    Preloader,
    IceComp,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      acfOptions: null,
      isPeripheral: null,
      landingPage: null,
      legalPage: null,
      navigation: null,
      previewId: null,
      previewLabel: null,
      loading: true, // Loading state to handle preloader visibility
    })

    const fetchAcfOptions = async () => {
      try {
        const response = await axios.get('acf/v3/group_669fc0a1095e6')
        state.acfOptions = response.data

        // console.log('Fetched ACF options:', response.data)
      } catch (error) {
        // console.error('Error fetching ACF options:', error)
      }
    }

    const fetchLandingPage = async (id) => {
      try {
        const response = await axios.get(`wp/v2/landing/${id}`)
        state.landingPage = response.data
        // console.log('Fetched Landing Page:', response.data)

        await Promise.all(
          state.landingPage.acf_blocks.map(async (block) => {
            if (
              block.blockName === 'acf/pricedisplay' &&
              block.attrs.data.imgbg
            ) {
              const imgResponse = await axios.get(
                `wp/v2/media/${block.attrs.data.imgbg}`
              )
              block.imgDetails = imgResponse.data
            }
          })
        )
      } catch (error) {
        console.error('Error fetching landing page:', error)
      }
    }

    const fetchLegalPage = async (id) => {
      try {
        const response = await axios.get(`wp/v2/pages/${id}`)
        state.legalPage = response.data
        console.log('Fetched Legal Page:', response.data)
      } catch (error) {
        console.error('Error fetching legal page:', error)
      }
    }

    const fetchNavigation = async (id) => {
      try {
        const response = await axios.get(`wp/v2/navigationlanding/${id}`)
        state.navigation = response.data
        // console.log('Fetched Navigation:', response.data)

        if (state.navigation.acf_blocks) {
          await Promise.all(
            state.navigation.acf_blocks.map(async (block) => {
              if (block.attrs.data.imgbg) {
                const imgResponse = await axios.get(
                  `wp/v2/media/${block.attrs.data.imgbg}`
                )
                block.imgDetails = imgResponse.data
              }
            })
          )
        }
      } catch (error) {
        console.error('Error fetching navigation:', error)
      }
    }

    const initializeData = async () => {
      state.loading = true // Ensure loading starts
      try {
        await fetchAcfOptions()
        // console.log('state.isP', state.isPeripheral)
        // console.log('state.id', state.previewId)
        if (state.isPeripheral === 'datenschutz') {
          await fetchLegalPage(state.acfOptions.legal.datenschutz)
        } else if (state.isPeripheral === 'impressum') {
          await fetchLegalPage(state.acfOptions.legal.impressum)
        } else if (state.isPeripheral === 'teilnahmebedingungen') {
          await fetchLegalPage(state.acfOptions.legal.teilnahmebedingungen)
        } else if (state.isPeripheral === 'preview') {
          // console.log('Preview page')
          // console.log(state.previewId + ' previewId')
          // console.log('versions', state.acfOptions.versions)
          // versions is array of objects, find entry where id matches previewId, then fetch that entry landingpage
          const previewVersion = state.acfOptions.versions.find(
            (version) => version.id === state.previewId
          )
          // console.log('previewVersion', previewVersion)
          if (previewVersion) {
            state.previewLabel = previewVersion.version
            await fetchLandingPage(previewVersion.landingpage)
          }
        } else if (state.acfOptions?.curver?.landingpage) {
          await fetchLandingPage(state.acfOptions.curver.landingpage)
        }
        if (state.acfOptions?.curver?.curnav) {
          await fetchNavigation(state.acfOptions.curver.curnav)
        }
      } catch (error) {
        console.error('Error initializing data:', error)
      } finally {
        state.loading = false // Set loading to false after data fetch
      }
    }

    watch(
      route,
      async (newRoute) => {
        state.loading = true // Start loading for route change
        if (newRoute.name === 'privacy') {
          state.isPeripheral = 'datenschutz'
        } else if (newRoute.name === 'imprint') {
          state.isPeripheral = 'impressum'
        } else if (newRoute.name === 'teilnahme') {
          state.isPeripheral = 'teilnahmebedingungen'
        } else if (newRoute.name === 'preview') {
          state.isPeripheral = 'preview'
          state.previewId = newRoute.params.id
        } else {
          state.isPeripheral = ''
        }
        await initializeData() // Reinitialize data on route change
      },
      { immediate: true }
    )

    onMounted(initializeData)

    const showNavBar = computed(() => !!state.acfOptions)
    const showFooter = computed(() => !!state.acfOptions)

    return {
      ...toRefs(state),
      showNavBar,
      showFooter,
    }
  },
}
</script>

<style>
.preloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: 0.3s;
}
.preloaderWrapper.finish {
  animation: preLoadOut 1s forwards;
}
@keyframes preLoadOut {
  0% {
    opacity: 1;
  }
  50% {
    transition: scale(8);
  }
  99% {
    opacity: 0;
    transition: scale(10);
  }
  100% {
    display: none;
  }
}
.previewBadge {
  position: fixed;
  top: 8vh;
  left: 8vw;
  z-index: 9999;
}
</style>
