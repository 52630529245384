<template>
  <div>
    <div class="w-full">
      <div class="bookbuyCont py-8 mb-12">
        <div class="container text-center">
          <div>
            <div class="buyBookHeaderImg">
              <img
                :src="getImageUrl(block.attrs.data.bookimg)"
                :alt="getImageAlt(block.attrs.data.bookimg, 'Book Image')"
              />
            </div>
            <h2
              v-html="formatContent(block.attrs.data.title)"
              class="bigHeadline relative z-10 text-white"
            ></h2>
            <div class="subHeadline">
              {{ block.attrs.data.subtitle || 'Subtitle not available' }}
            </div>
            <div class="booksBuySliderCont">
              <swiper
                :slides-per-view="3"
                :space-between="30"
                :breakpoints="swiperBreakpoints"
                pagination
                :loop="false"
                :autoplay="{ delay: 5000 }"
                :speed="1000"
              >
                <swiper-slide
                  v-for="(shop, index) in block.attrs.data.shops || []"
                  :key="index"
                >
                  <div class="shop-item">
                    <a
                      :href="shop.link || '#'"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        :src="getImageUrl(shop.logo)"
                        :alt="getImageAlt(shop.logo, 'Shop Logo')"
                        class="shop-logo"
                      />
                      <BtnComp
                        variant="outline"
                        btntype="a"
                        btnclass="fCenter w-full mt-6"
                        :href="shop.link || '#'"
                        :name="block.attrs.data.shopbtnlabel || 'Shop Now'"
                      />
                    </a>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import BtnComp from '../framework/btnComp.vue'

export default {
  props: {
    block: Object,
  },
  components: {
    BtnComp,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      speed: 300,
      swiperBreakpoints: {
        // When window width is >= 1200px
        1200: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          allowTouchMove: false,
          // Disable autoplay
          autoplay: {
            delay: 0,
          },
        },
        // When window width is >= 768px
        768: {
          slidesPerView: 4,
          slidesPerGroup: 1,
          allowTouchMove: true,
          autoplay: {
            delay: 5000,
          },
        },
        // When window width is >= 576px
        576: {
          slidesPerView: 3,
          slidesPerGroup: 1,
          allowTouchMove: true,
          autoplay: {
            delay: 5000,
          },
        },
        // When window width is < 0px
        0: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 10,
          allowTouchMove: true,
          autoplay: {
            delay: 5000,
          },
        },
      },
    }
  },
  methods: {
    formatContent(content) {
      // Replace \n with <br> for HTML line breaks
      if (content) {
        return content.replace(/\n/g, '<br>')
      }
      return 'Title not available'
    },
    getImageUrl(image) {
      // Fallback URL for image
      return image && image.url ? image.url : 'default-image.jpg'
    },
    getImageAlt(image, defaultAlt) {
      // Fallback alt text for image
      return image && image.alt ? image.alt : defaultAlt
    },
  },
}
</script>

<style>
.buyBookHeaderImg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0rem;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}
.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}
.swiper-pagination-bullet {
  background: #fff;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
.swiper {
  padding-bottom: 50px;
}
.buyBookHeaderImg img {
  max-width: 260px;
}

.booksBuySliderCont {
  margin-top: 20px;
}

.shop-item {
  text-align: center;
}

.shop-logo {
  max-width: 200px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid var(--cRed);
  border-radius: 5px;
}

.shop-title {
  font-size: 16px;
}

.swiper-slide .shop-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
}

@media (min-width: 1200px) {
  .swiper-container,
  .swiper {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
  }

  .swiper-slide:last-of-type {
    margin-right: 0 !important;
  }
}

@media (max-width: 1300px) {
  .shop-logo {
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .buyBookHeaderImg img {
    max-width: 180px;
  }
}
</style>
