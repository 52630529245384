import { reactive } from 'vue'

// Singleton state
const modalState = reactive({
  winOverlay: false,
})

export const useModal = () => {
  return {
    modalState,
  }
}
